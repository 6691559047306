import Vue from 'vue';
import Component from 'vue-class-component';
import template from './password-input.html';
import './password-input.scss';
import {translate} from '../../../main';
import {zxcvbn, ZxcvbnOptions} from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import {Prop} from 'vue-property-decorator';

@Component({
    template: template,
    props: {
        id: String,
        label: String,
        placeholder: String,
        autofocus: Boolean,
    },
})
export default class PasswordInput extends Vue {
    private passwordWarning: string | null = null;
    private passwordSuggestions: string[] = [];
    private password = '';
    @Prop({
        type: String,
        default: 'password_input',
    })
    private id!: string;
    @Prop({
        type: String,
        default: () => translate('registration.userInfo.info.field.password'),
    })
    private label!: string;
    @Prop({
        type: String,
        default: () => translate('registration.userInfo.info.placeholder.password'),
    })
    private placeholder!: string;
    @Prop({
        type: Boolean,
        default: false,
    })
    private autofocus!: boolean;
    private errors: {
        [field: string]: string;
    } = {};

    private async mounted(): Promise<void> {
        ZxcvbnOptions.setOptions({
            dictionary: {
                ...zxcvbnCommonPackage.dictionary,
                ...zxcvbnEnPackage.dictionary,
            },
            translations: zxcvbnEnPackage.translations,
        });
        this.$nextTick(() => {
            this.checkPassword();
            if (this.autofocus) {
                this.focus();
            }
        })
    }

    protected async passwordChanged() {
        const passes = await this.checkPassword();
        this.$emit('password-changed', {
            password: this.password,
            passes: passes,
        });
    }

    public get typedPassword() {
        return this.password;
    }

    public async checkPassword(): Promise<boolean> {
        let passes = this.password != null && this.password.length >= 8;
        if (!passes) {
            this.errors['password'] = translate('registratbooleanion.userInfo.validationErrors.invalidPassword', {
                amount: 8,
            });
            return false;
        }
        const pwData = await zxcvbn(this.password);
        if (pwData && pwData.feedback) {
            if (pwData.feedback.warning) {
                this.passwordWarning = pwData.feedback.warning;
            } else {
                this.passwordWarning = null;
            }
            if (pwData.feedback.suggestions) {
                this.passwordSuggestions = pwData.feedback.suggestions;
            } else {
                this.passwordSuggestions = [];
            }
        }
        if (pwData && pwData.crackTimesSeconds.onlineThrottling100PerHour <= 3600 * 24 * 365) {
            this.errors['password'] = translate('registration.userInfo.validationErrors.crackablePassword', {
                time: pwData.crackTimesDisplay.onlineThrottling100PerHour,
            });
            passes = false;
        }
        if (passes) {
            delete this.errors['password'];
        }
        return passes;
    }

    protected handleBlur(filedName: string): void {
        this.checkPassword();
    }

    public focus() {
        const input = this.$refs.pwInput as HTMLInputElement;
        if (input) {
            input.focus();
        }
    }
}
